import { writable, derived } from "svelte/store";
import { get } from "../api.js";

export const tableauPromise = writable(new Promise(() => {}));
export const insertedMessage = writable(null);
export const spritesheetPath = writable(null);
export const showAnimation = writable(false);
export const animationRunning = writable(false);
export const animationDisplayed = derived(
  [showAnimation, animationRunning],
  ([$showAnimation, $animationRunning]) => {
    if ($animationRunning || $showAnimation) {
      return true
    }
    return false
  }

);
export const animationHoldLastFrame = writable(false);
export const animationName = writable("working");
export const flipbooksPromise = writable(new Promise(() => {}));
export const flipbooks = derived(flipbooksPromise, async ($flipbooksPromise, set) => {
  const _flipbookLayers = await $flipbooksPromise
  const _flipbooks = {}
  _flipbookLayers.layers.forEach((l) => {
    const tokens = l["name"].split("-")
    if (tokens.length == 2) {
      const _flipbook = _flipbooks[tokens[0]] ??= {}
      _flipbook[tokens[1]] = l
    }
  })
  set(_flipbooks);
});
export const tableau = derived(tableauPromise, async ($tableauPromise, set) => {
  insertedMessage.set(null);
  showAnimation.set(true);
  const _tableau = await $tableauPromise
  set(_tableau);
  showAnimation.set(false);
});
export const baseUrl = writable(null);
export const messages = derived(
  [tableau, insertedMessage],
  ([$tableau, $insertedMessage]) => {
    const _messages = [];
    if ($insertedMessage !== null) {
      _messages.push({ message: $insertedMessage });
    }
    if ($tableau === undefined) {
      return _messages;
    }
    $tableau.messages.forEach((m) => _messages.push(m));
    return _messages;
  }
);
export const userDetails = writable(null);
export const viewedCharacter = writable(null);
export const viewedNpc = writable(null);
export const viewedItem = writable(null);
export const cenotaphPk = writable(null);
export const cenotaph = derived(cenotaphPk, async ($cenotaphPk, set) => {
  if ($cenotaphPk === null) {
    set(null);
  }
  set(await get(`v1/maze/cenotaphs/${$cenotaphPk}/`));
});
export const pixiFacts = writable({
  tileWidth: 40,
  tileHeight: 30,
  width: 40,
  height: 30,
})
export const clickMode = writable(null);  // TODO: decentralize the setting of this!
export const isSensingMode = derived([tableau, clickMode], ([$tableau, $clickMode]) => {
  if ($clickMode == "look" && $tableau.room.attributes.brightness > 0) {
    return true
  }
  if ($clickMode == "sniff" && $tableau.room.attributes.cleanliness > 0) {
    return true
  }
  if ($clickMode == "creep" && $tableau.room.attributes.cleanliness < 2) {
    return true
  }
  if ($clickMode == "scry" && $tableau.room.attributes.sanctity > 0) {
    return true
  }
  if ($clickMode == "spawn" && $tableau.room.attributes.cleanliness > 0) {
    return true
  }
  if ($clickMode == "listen" && $tableau.room.occupants.length < 2) {
    return true
  }
  if ($clickMode == "analyze" ) {
    return true
  }
  return false
});
export const inspectorTarget = writable(null);
const doorModes = {
  "default": {
    inspectorPrefix: "move to"
  },
  "aim": {
    inspectorPrefix: "aim at"
  },
  "arm": {
    inspectorPrefix: "place trap at"
  },
  "unlock": {
    inspectorPrefix: "actuate lock for"
  },
  "bash": {
    inspectorPrefix: "bash door"
  },
};
const examineModes = {
  "look": {
    inspectorPrefix: "Look at"
  },
  "sniff": {
    inspectorPrefix: "Sniff"
  },
  "scry": {
    inspectorPrefix: "Scry"
  },
  "creep": {
    inspectorPrefix: "Creep on"
  },
  "spawn": {
    inspectorPrefix: "Spawn on"
  },
  "listen": {
    inspectorPrefix: "Listen to"
  },
  "analyze": {
    inspectorPrefix: "Analyze"
  },
};

export const inspectorData = derived(
  [inspectorTarget, clickMode, tableau],
  ([detail, mode, tableau]) => {
    const inspector = {}
    if (detail === null) {
      inspector.header = "";
      inspector.text = "";
      return null
    }
    if (detail.exit !== undefined) {
      inspector.header = "Door";
      inspector.text = "Can't do that.";
      if (doorModes[mode] !== undefined) {
        inspector.text = `${doorModes[mode].inspectorPrefix} ${detail.exit.name}`;
      }
      if (detail.exit.locked) {
        inspector.text += " (locked)";
      } else if (detail.exit.visible_traps.length > 0) {
        inspector.text += " (trapped)";
      }
    }
    if (detail.drop !== undefined) {
      inspector.header = "Item";
      inspector.text = `Drop ${detail.drop.abstract_item.name}`;
    }
    if (detail.item !== undefined) {
      inspector.header = "Item";
      const takeable = detail.item.items.find((i) => i.is_takeable);
      if (mode == "default") {
        if (takeable !== undefined) {
          inspector.text = `Pick up ${detail.item.abstract_item.name}`;
        } else if (tableau.character.bodyparts.length > 0) {
          if (detail.item.is_active && detail.item.abstract_item.active_adjective !== null) {
            inspector.text = `Inspect ${detail.item.abstract_item.active_adjective} ${detail.item.abstract_item.name}`;
          } else {
            inspector.text = `Inspect ${detail.item.abstract_item.name}`;
          }

        } else {
          if (detail.item.is_active && detail.item.abstract_item.active_adjective !== null) {
            inspector.text = `${detail.item.abstract_item.active_adjective} ${detail.item.abstract_item.name}`;
          } else {
            inspector.text = `${detail.item.abstract_item.name}`;
          }
        }
      } else if (examineModes[mode] !== undefined) {
        inspector.text = `${examineModes[mode].inspectorPrefix} ${detail.item.abstract_item.name}`;
      } else if (mode == "bash") {
        inspector.text = "You cannot bash this";
      }
    }
    if (detail.npc !== undefined) {
      inspector.header = "NPC";
      if (mode == "default") {
        inspector.text = `Greet ${detail.npc.name}`;
      } else if (examineModes[mode] !== undefined) {
        inspector.text = `${examineModes[mode].inspectorPrefix} ${detail.npc.name}`;
      } else if (mode == "bash") {
        inspector.text = `Bash ${detail.npc.name}`;
      }
    }
    if (detail.occupant !== undefined) {
      inspector.header = "Player Character";
      if (mode == "default") {
        if (detail.occupant.pk == tableau.character.pk) {
          inspector.text = `It's you, a ${detail.occupant.kind}!`;
        } else {
          inspector.text = `Greet ${detail.occupant.name}`;
        }
      } else if (examineModes[mode] !== undefined) {
        inspector.text = `${examineModes[mode].inspectorPrefix} ${detail.occupant.name}`;
      } else if (mode == "bash") {
        inspector.text = `Bash ${detail.occupant.name}`;
      }
    }
    if (detail.cenotaph !== undefined) {
      inspector.header = "Cenotaph";
      if (mode == "default") {
        if (tableau.character.bodyparts.length > 0) {
          inspector.text = `Inspect ${detail.cenotaph.name}'s Cenotaph<br />${detail.cenotaph.birth}&mdash;${detail.cenotaph.death}`;
        } else {
          inspector.text = `${detail.cenotaph.name}<br />${detail.cenotaph.birth}&mdash;${detail.cenotaph.death}`;
        }
      } else if (examineModes[mode] !== undefined) {
        inspector.text = `${examineModes[mode].inspectorPrefix} ${detail.cenotaph.name}'s Cenotaph`;
      } else if (mode == "bash") {
        inspector.text = "You cannot bash this";
      }
    }
    if (detail.cursed !== undefined) {
      inspector.text = "It's stuck!";
    }
    return inspector
});
