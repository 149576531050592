<script>
import AnimationScreen from "./components/grottopaint/animations/AnimationScreen.svelte";
import { spritesheetPath } from "./components/stores.js";

spritesheetPath.set("/static/grottopaint/spritesheets/grotto40x30-cp437@1x.png");
</script>

<a href="/game/play/">
  <AnimationScreen
    flipbookName="attract"
    flipbookRepeat={true}
    flipbookRate=7
  />
</a>

<style>
div.graphics {
  min-height: 120px;
}
</style>
