<script>
import { createEventDispatcher } from "svelte";
import { Sprite } from 'svelte-pixi';
import { blitTexture } from './lib.js';

import Tile from "./Tile.svelte";

const dispatch = createEventDispatcher();

export let name = "whocares";
export let jitter = false;
export let data;
export let width;

</script> 

{#each data as data, i}
  <Tile
    frameName={name}
    {jitter}
    {data}
    xIndex={parseInt(i % width)}
    yIndex={parseInt(i / width)}
  />
{/each}
