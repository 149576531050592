<script>

import * as PIXI from 'pixi.js'

import { Application, Loader, Text } from 'svelte-pixi'

import { showAnimation, animationRunning, animationDisplayed, animationName, flipbooksPromise, flipbooks, spritesheetPath } from "../../stores.js";
import { get_static } from "../../../api.js";

import Animation from "../Animation.svelte";
import Flipbook from "../animations/Flipbook.svelte";

export let flipbookUrl = "/static/grottopaint/maps/flipbooks.tmj";
export let flipbookName = "attract";
export let flipbookRepeat = true;
export let flipbookRate = 7;

let flipbookNames = []
flipbooksPromise.set(get_static(flipbookUrl))
flipbooks.subscribe((value) => {
  if (value !== undefined) {
    flipbookNames = Object.keys(value)
  }
})
export let targetWidth = 1600
export let targetHeight = 900

$: tileTextures = [$spritesheetPath];

showAnimation.set(true);

</script>

<div class="graphics">
  <Application
    backgroundColor={0xffffff}
    width={targetWidth}
    height={targetHeight}
    resolution=1
  >
    <Loader resources={tileTextures}>
      <slot slot="loading">
        <Text
          text="..loading"
          anchor={0.5}
          x={200}
          y={200}
          style={{
            fill: 'white'
          }}
        />
      </slot>
    </Loader>

    {#if flipbookNames.find((n) => n === "attract") !== undefined}
      <Animation>
        <Flipbook
          name="{flipbookName}"
          repeat={flipbookRepeat}
          jitter={true}
          rollToWorking={false}
          flipRate="{flipbookRate}"
        />
      </Animation>
    {/if}
  </Application>
</div>

<style>
div.graphics {
  min-height: 120px;
}
</style>
