<script>
import { createEventDispatcher } from "svelte";
import { onTick } from 'svelte-pixi';

import { flipbooks, animationRunning, animationHoldLastFrame } from "../../stores.js";

import Frame from "../Frame.svelte";
const dispatch = createEventDispatcher();

let page = 1;  // 1-indexed!
let tickCounter = 0;
export let name = "exit_action"
export let width = 40;
export let height = 30;
export let flipRate = 5;
export let repeat = false;
export let rollToWorking = true;
export let jitter = true

onTick(() => {
  if (! $animationRunning) {
    return
  }
  if (! $animationHoldLastFrame || $flipbooks[name].hasOwnProperty(page+1)) {
    tickCounter += 1;
    if (tickCounter % flipRate == 0) {
      // page flips
      page += 1;
    }
  }
})

function getLayer(_page) {
  if (! $flipbooks[name].hasOwnProperty(_page)) {
    if (repeat) {
      _page = 1;
      tickCounter = 0;
      page = 1;
    } else if (rollToWorking) {
      name = "working";
      _page = 1;
      tickCounter = 0;
      dispatch("complete", {"note": "rolling into 'working' animation"})
      page = 1;
    } else {
      dispatch("ended", {"note": "animation stopped"})
      animationRunning.set(false);
      return {
        data: $flipbooks[name][1].data,
        width: width,
      }
    }
  }
  return {
    data: $flipbooks[name][_page].data,
    width: width,
  }
}
$: layer = getLayer(page);
</script>

<Frame
  name="{name}-animation"
  {jitter}
  {...layer}
/>
