import * as PIXI from 'pixi.js'
import {get} from 'svelte/store'
import { Howl } from "howler";

import { spritesheetPath } from "../stores.js";
import audiospriteData from "../../assets/sound/grottoAudiosprite.json";

const jitterable = [128, 134, 141, 175, 176, 177, 216];  // these have to be 0 indexed!

export function blitTexture(data, width, height, jitter=false, frameName="empty") {
  if (get(spritesheetPath) === null) {
    return
  }
  let index = data & 0xffff;
  // if (index == 0) {
  //   return new PIXI.Texture(
  //     PIXI.Texture.from(spritesheet),
  //   );
  // }
  index -= 1;
  const sourceX = parseInt(index % 23) * width;
  const sourceY = parseInt(index / 23) * height;
  const blitRectangle = new PIXI.Rectangle(
    sourceX,
    sourceY,
    width,
    height
  );

  const _texture = new PIXI.Texture(
    PIXI.Texture.from(
      get(spritesheetPath),
      {resolution: 1}
    ),
    blitRectangle
  );

  let flipX = data & 0x80000000;
  let flipY = data & 0x40000000;
  let rotateMode = PIXI.groupD8.E;
  if (jitter && jitterable.indexOf(index) !== -1) {
    flipX = Math.floor(Math.random() * 2)
    if (! flipX) {
      flipY = Math.floor(Math.random() * 2)
    }
  }
  if (flipX && flipY) {
    rotateMode = PIXI.groupD8.W;
  } else if (flipX) {
    rotateMode = PIXI.groupD8.MIRROR_HORIZONTAL;
  } else if (flipY) {
    rotateMode = PIXI.groupD8.MIRROR_VERTICAL;
  }

  _texture.rotate = rotateMode;
  return _texture
}

class Sound {
  constructor() {
    var sprites = {}
    for (var key in audiospriteData.spritemap) {
      var sprite = audiospriteData.spritemap[key];
      sprites[key] = [sprite.start * 1000, (sprite.end - sprite.start) * 1000];
    }
    this.soundboard = new Howl({
      src: [
        '/static/sound/grottoAudiosprite.ogg',
        '/static/sound/grottoAudiosprite.m4a',
        '/static/sound/grottoAudiosprite.mp3',
        '/static/sound/grottoAudiosprite.ac3'
      ],
      sprite: sprites,
      volume: 1
    });
  }
  playSound(name) {
    this.soundboard.play(name);
  }
  hallway() {
    this.playSound('hallway');
  }
}

export const sound = new Sound();
