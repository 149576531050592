<script>
import { Sprite } from 'svelte-pixi';
import { blitTexture } from './lib.js';
import { pixiFacts } from "../stores.js";

export let data;
export let xIndex;
export let yIndex;
export let jitter = false;
export let frameName = "blank";

let sprite;

let width = 40;
let height = 30;
pixiFacts.subscribe(fax => {
  width = fax.tileWidth;
  height = fax.tileHeight;
})

function mouseIn() {
  sprite.alpha = 0.5;
}
function mouseOut() {
  sprite.alpha = 1;
}
</script>

<Sprite
  bind:instance={sprite}
  texture={blitTexture(data, width, height, jitter, frameName)}
  width={width}
  height={height}
  x={xIndex * width}
  y={yIndex * height}
  interactive={data > 0}
  on:mouseover={mouseIn}
  on:mouseout={mouseOut}
/>
