import Attract from "./Attract.svelte";

const target = document.getElementById("attract-target");

function replaceTarget(target) {
  const attract = new Attract({
    target: target.parentElement,
    anchor: target,
    props: JSON.parse(document.getElementById("attract-props").textContent),
  });
  target.remove();
  return attract;
}

const attract = replaceTarget(target);

export default attract;
