<script>
import { createEventDispatcher } from "svelte";
import { Sprite, onTick } from 'svelte-pixi';

import { blitTexture, sound } from './lib.js';
import {
  showAnimation,
  animationRunning,
  pixiFacts,
  flipbooks,
} from "../stores.js";

import Flipbook from "./animations/Flipbook.svelte";

const dispatch = createEventDispatcher();

export let duration = 3;
export let interruptable = false;
export let background = {data:[], width:0};
export let flipbookName = "exit_action"
export let repeat = false;

let tickCounter = 0;
let soundToPlay = undefined;

function resetTicks () {
  tickCounter = 0
  soundToPlay = {
    bash: "hit",
    bodypart: "magic", // repeat
    candle_light: "magic",
    candle_place: "plunk1", // repeat
    clean: "scrub2",
    equip: "magic",
    fire_arrow: "bow",
    incense_light: "tone5",
    move: "hallway",
    place_trap: "plunk1",
    poop: "tap2",
    toggle_lock: "lock",
    wumpus_bite: "ouch",
    // : "arrow_hit",
    // : "arrow_miss",
    // : "feets",
    // : "fireball",
    // : "heal",
    // : "levelout",
    // : "pickup",
    // : "plunk3",
    // : "scrub3",
    // : "tap1",
    // : "tap3",
    // : "tap4",
    // : "tone1",
    // : "tone2",
    // : "tone3",
    // : "tone4",
  }[flipbookName]
}
$: resetTicks(flipbookName)


function determineRunning(value, _interruptable) {
  if (! value && _interruptable) {
    animationRunning.set(false);
  }
}

$: determineRunning($showAnimation, interruptable)


onTick((delta) => {
  if (tickCounter == 0) {
    if (soundToPlay !== undefined) {
      sound.playSound(soundToPlay);
    }
    animationRunning.set(true);
  }
  tickCounter += 1;
})

</script>

<slot {tickCounter}>
  <Flipbook
    name="{flipbookName}"
    {repeat}
    on:complete={() => interruptable = true}
  />
</slot>
